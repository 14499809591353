
import { defineComponent, ref, onMounted, watchEffect } from 'vue';
import { Apollo, Notify } from '@/core/services';
// import Search from "@/components/search/Search.vue";
import AdvanceSearch from '../../../components/search/AdvanceSearch.vue';
import { useI18n } from 'vue-i18n';
import Table from '../../../components/Table/Table.vue';
import { Modal } from 'bootstrap';
import InnerLoader from '../../../components/InnerLoader.vue';
import { GET_JOBS_MONITOR_DATA, SEARCH_FAILED_JOBS } from '@/modules/failed-jobs/graphql/Queries';
import { DELETE_FAILED_JOB, RETRY_FAILED_JOB } from '@/modules/failed-jobs/graphql/Mutations';
import { dropdown_handler } from '../../../core/helpers/dropdownHandler';
import { useStore } from 'vuex';

export default defineComponent({
    name: 'subscription list',
    components: {
        // Search,
        Table,
        InnerLoader,
        AdvanceSearch
    },
    setup() {
        const i18n = useI18n();
        const store = useStore();
        const loader = ref(false);
        const loading = ref(false);
        const failed_jobs = ref({}) as Record<any, any>;
        const failed_data = ref({}) as Record<any, any>;
        const modal: any = ref();
        const failed_scenarios = ref('');
        const popup_title = ref('');
        const systemLocale = ref();
        const activeIndex = ref(-1);
        const currentPage = ref(0);
        const clickHandler = ref() as Record<any, any>;
        const pagination = ref({}) as Record<any, any>;
        const filterOption = ref({}) as Record<any, any>;
        // const statusList = ref([]) as Record<any, any>;

        const statusList = ref([
            {
                label: 'message.SUCCESS',
                value: 1
            },
            // {
            // 	label: "message.FAILED",
            // 	value: 2,
            // },
            {
                label: 'message.RUNNING',
                value: 0
            }
        ]);

        filterOption.value = {
            job_status: true,
            date_from: true,
            date_to: true,
            jobs_date_filter: true
        };

        const columns = ref([
            {
                label: 'message.ID',
                key: 'id'
            },
            {
                label: 'message.NAME',
                key: 'name'
            },
            {
                label: 'message.STATUS',
                key: 'status'
            },
            {
                label: 'message.CREATED_AT',
                key: 'finished_at',
                textAlignment: 'center'
            },
            // {
            // 	label: "message.EXCEPTION",
            // 	key: "exception",
            // 	textAlignment: "center",
            // },
            {
                label: 'message.ACTIONS',
                key: 'actions',
                textAlignment: 'center'
            }
        ]);
        const filterObject = ref([
            {
                label: 'message.QUEUE',
                value: 'queue'
            }
        ]);

        const dashboard = ref({
            total_jobs: 0,
            total_failed: 0,
            total_running: 0
        });

        watchEffect(() => {
            systemLocale.value = i18n.fallbackLocale.value;
        });

        const handleClickOutside = event => {
            if (!event.target.closest('.action-btn')) {
                activeIndex.value = -1;
            }
        };

        const dropdownHandler = (index, id) => {
            activeIndex.value = index === id ? null : id;
        };

        const handleCurrentChange = (val: number) => {
            clickHandler.value(val);
        };

        const get_failed_jobs = (page = 1, reload = false) => {
            loader.value = true;
            const sub = Apollo.watchQuery({
                query: GET_JOBS_MONITOR_DATA,
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'cache-only',
                errorPolicy: 'all',
                variables: {
                    page: page,
                    limit: 10
                }
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    Notify.error(i18n.t('message.SOMETHING_WENT_WRONG'));
                }
                reload == true ? (currentPage.value = 1) : false;
                clickHandler.value = get_failed_jobs;
                failed_jobs.value = data?.jobs_monitor?.data;
                dashboard.value.total_jobs = data?.jobs_monitor?.data ? data?.jobs_monitor?.data[0]?.total_count + JSON.parse(data?.jobs_monitor?.data[0].failed_jobs)[0]?.total_count : 0;
                dashboard.value.total_failed = data?.jobs_monitor?.data ? JSON.parse(data?.jobs_monitor?.data[0]?.failed_jobs)[0]?.total_count : 0;
                dashboard.value.total_running = data?.jobs_monitor?.data ? data?.jobs_monitor?.data[0].total_running : 0;
                failed_data.value = JSON.parse(data?.jobs_monitor?.data[0].failed_jobs);
                pagination.value = data?.jobs_monitor;
                loader.value = false;
            });
        };

        onMounted(() => {
            get_failed_jobs();
            document.addEventListener('click', handleClickOutside);
        });

        const searchHandler = (page = 0) => {
            const searchData = store.getters.getSearchData;
            loader.value = true;
            Apollo.watchQuery({
                query: SEARCH_FAILED_JOBS,
                errorPolicy: 'all',
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'cache-only',
                variables: {
                    page: page,
                    limit: 10,
                    filter: JSON.stringify(searchData?.data?.select),
                    search_key: searchData?.data?.input
                }
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loader.value = false;
                }
                clickHandler.value = searchHandler;
                failed_jobs.value = data?.search_failed_jobs?.data;
                dashboard.value.total_jobs = Object.entries(data?.search_failed_jobs?.data).length > 0 ? data?.search_failed_jobs?.data[0]?.total_count : 0;
                dashboard.value.total_failed = Object.entries(data?.search_failed_jobs?.data).length > 0 ? JSON.parse(data?.search_failed_jobs?.data[0].failed_jobs)[0]?.total_count : 0;
                dashboard.value.total_running = Object.entries(data?.search_failed_jobs?.data).length > 0 ? data?.search_failed_jobs?.data[0].total_running : 0;
                pagination.value = data?.search_failed_jobs;
                loader.value = false;
            });
        };

        const getScenario = (data, title) => {
            failed_scenarios.value = data;
            popup_title.value = title;
            modal.value = new Modal(document.getElementById('pop_up_modal'));
            modal.value.show();
        };

        const retryJob = uuid => {
            store.getters.appInstance.$messageBox
                .confirm(`${i18n.t('message.ARE_YOU_SURE_TO_HIT_JOB_AGAIN')}?`, i18n.t('message.INFO'), {
                    confirmButtonText: i18n.t('message.YES'),
                    cancelButtonText: i18n.t('message.NO'),
                    type: 'info'
                })
                .then(async () => {
                    loader.value = true;
                    Apollo.mutate({
                        mutation: RETRY_FAILED_JOB,
                        variables: { uuid: uuid },
                        update: (store, { data: { retry_job } }) => {
                            searchHandler(pagination.value.current_page);
                        }
                    });
                    Notify.success(i18n.t('message.JOB_IS_HIT_SUCCESSFULLY'));
                })
                .catch(() => {
                    loader.value = false;
                });
        };

        const deleteJob = uuid => {
            store.getters.appInstance.$messageBox
                .confirm(`${i18n.t('message.ARE_YOU_SURE_TO_DELETE_RECORD')}?`, i18n.t('message.INFO'), {
                    confirmButtonText: i18n.t('message.YES'),
                    cancelButtonText: i18n.t('message.NO'),
                    type: 'info'
                })
                .then(async () => {
                    loader.value = true;
                    Apollo.mutate({
                        mutation: DELETE_FAILED_JOB,
                        variables: { uuid: uuid },
                        update: (store, { data: { delete_job } }) => {
                            const readParse = store.readQuery({
                                query: GET_JOBS_MONITOR_DATA
                            }) as Record<any, any>;
                            const readData = JSON.parse(readParse.failed_jobs);
                            const data = readData.filter((failed_job: any) => failed_job.uuid !== delete_job);
                            store.writeQuery({
                                query: GET_JOBS_MONITOR_DATA,
                                data: {
                                    failed_jobs: [JSON.stringify(data)]
                                }
                            });
                            loader.value = false;
                            Notify.success(i18n.t('message.RECORD_DELETED_SUCCESSFULLY'));
                        }
                    }).catch(() => {
                        loader.value = false;
                    });
                });
        };

        return {
            loader,
            filterObject,
            failed_jobs,
            columns,
            failed_scenarios,
            popup_title,
            loading,
            get_failed_jobs,
            searchHandler,
            deleteJob,
            retryJob,
            getScenario,
            activeIndex,
            dropdownHandler,
            handleClickOutside,
            currentPage,
            clickHandler,
            handleCurrentChange,
            pagination,
            dashboard,
            filterOption,
            statusList,
            failed_data
        };
    }
});
