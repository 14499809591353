<template>
	<JobsMonitor />
</template>

<script>
import JobsMonitor from "../components/JobsMonitor.vue";
import { defineComponent, onMounted } from "vue";
import { setPageHeader } from "../../../core/helpers/toolbar";
export default defineComponent({
	components: {
		JobsMonitor,
	},
	setup() {
		onMounted(() => {
			setPageHeader({
				title: "message.JOBS_MONITOR",
				actionButton: null,
				breadCrumbs: [{ name: "message.HOME", link: "/dashboard" }, { name: "message.JOBS_MONITOR", link: "#" }, { name: "message.JOBS_MONITOR" }],
			});
		});
	},
});
</script>
