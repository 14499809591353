import { gql } from "graphql-tag";

export const DELETE_FAILED_JOB = gql`
	mutation DeleteFailedJob($uuid: String!) {
		delete_job(uuid: $uuid)
	}
`;

export const RETRY_FAILED_JOB = gql`
	mutation retryFailedJob($uuid: String!) {
		retry_job(uuid: $uuid)
	}
`;
