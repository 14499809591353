import { gql } from "graphql-tag";

export const JOBS_MONITOR_FIELDS = gql`
	fragment JobsMonitorFields on JobsMonitor {
		id
		job_uuid
		job_id
		name
		queue
		status
		started_at
		started_at_exact
		finished_at
		finished_at_exact
		attempt
		retried
		progress
		exception
		exception_message
		exception_class
		data
		total_count
		total_running
		failed_jobs
	}
`;

export const PAGINATION = gql`
	fragment Pagination on JobsMonitorPagination {
		total
		per_page
		current_page
		from
		to
		last_page
		has_more_pages
	}
`;

export default { JOBS_MONITOR_FIELDS, PAGINATION };
