import { gql } from "graphql-tag";
import { JOBS_MONITOR_FIELDS, PAGINATION } from "./Fragments";

export const GET_JOBS_MONITOR_DATA = gql`
    query GetJobsMonitor($page: Int, $limit: Int) {
        jobs_monitor(limit:$limit, page:$page){
            ...Pagination
            data {
                ...JobsMonitorFields
            }
        }
    }
    ${JOBS_MONITOR_FIELDS},
    ${PAGINATION},
`;

export const SEARCH_FAILED_JOBS = gql`

    query SearchFailedJobs($page: Int, $limit: Int, $filter:String,$search_key:String, $date_from:String , $date_to:String) {
        search_failed_jobs(limit:$limit, page:$page,filter:$filter, search_key:$search_key,date_from:$date_from , date_to:$date_to){
            ...Pagination
            data {
                ...JobsMonitorFields
            }
        }
    }
    ${JOBS_MONITOR_FIELDS},
    ${PAGINATION},
`;

export const GET_FAILED_JOBS = gql`
	query getFailedJobs {
		failed_jobs
	}
`;
